(function(exports, $) {

    function initInmuebleCards() {
        var $cards = $('.card-inmueble');

        $cards.each(function() {
            var $this = $(this),
                $selectores = $this.find('.selector a'),
                $tabs = $this.find('.precio'),
                $capaReveal = $this.find('.foto div.sabana');

            $selectores.click(function(e) {
                e.preventDefault();
                e.stopPropagation();

                $tabs.removeClass('active');

                $tabs.filter('.precio.' + $(this).attr('href').substr(1)).addClass('active');

                return false;
            });

            $selectores.eq(0).click();

            $this.hover(function() {
                $capaReveal.addClass('abierto');
            }, function() {
                $capaReveal.removeClass('abierto');
            });

        });
    }

    function initTraductor() {
        $('header.main .traducir li').click(function(e) {
            e.preventDefault();
            e.stopPropagation();

            window.location.hash = 'googtrans(' + $(this).attr('class') + ')';
            window.location.reload();

            return false;
        });
    }

    function initSubscribeNewsletter() {
        var $formSuscribir = $('form#suscribir-newsletter');

        $formSuscribir.on('submit', function(e) {
            e.stopPropagation();
            e.preventDefault();

            var $inputEmail = $formSuscribir.find('#suscribir-email'),
                $boton = $formSuscribir.find('button'),
                $flash = $formSuscribir.find('.suscribir-flash');

            var textBoton = $boton.html();
            $boton
                .html('Por favor, espera...')
                .prop('disabled', true);

            $flash.removeClass('mostrar');

            $.ajax({
                'url': $formSuscribir.attr('action'),
                'method': 'POST',
                'data': {'email': $inputEmail.val() }
            }).done(function(response) {
                $flash.html(response['reason']);

                if(response['status'] == 'ok') {
                    $inputEmail.val('');
                }

            }).fail(function() {
                $flash.html('Ocurrió un error. Por favor inténtalo más tarde');
            }).always(function() {
                $boton
                    .html(textBoton)
                    .prop('disabled', false);
                $flash.addClass('mostrar');
            });

            return false;
        });
    }

    function googleTranslateElementInit() {
        new google.translate.TranslateElement({pageLanguage: 'es', includedLanguages: 'de,en,fr', layout: google.translate.TranslateElement.InlineLayout.SIMPLE, autoDisplay: false}, 'google_translate_element');
    }

    $(document).ready(function() {
        $('.lazy').unveil();
        initInmuebleCards();
        initTraductor();
        initSubscribeNewsletter();
    });

    exports.googleTranslateElementInit = googleTranslateElementInit;

})(window, jQuery);

